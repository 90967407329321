var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modalEditFormContent",
        attrs: {
          id: "modal-edit-form-content",
          size: "full",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
          "footer-class": "flex-nowrap",
          centered: ""
        },
        on: { shown: _vm.initializeSurveyCreator },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("modals.editFormContent.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [_c("div", { attrs: { id: "surveyCreator" } })]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.cancelModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.validateFormContent }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modals.editFormContent.validate")) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }