<template>
  <div>
    <b-modal
      id="modal-edit-form-content"
      ref="modalEditFormContent"
      size="full"
      no-close-on-backdrop
      no-close-on-esc
      footer-class="flex-nowrap"
      centered
      @shown="initializeSurveyCreator"
    >
      <template #modal-header>
        <h3>{{ $t('modals.editFormContent.title') }}</h3>
      </template>

      <template #default>
        <div id="surveyCreator" />
      </template>

      <template #modal-footer>
        <b-button class="p-3 border-right-light-grey" @click="cancelModal" block>
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button variant="primary" class="p-3" block @click="validateFormContent">
          {{ $t('modals.editFormContent.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import { SurveyCreator, editorLocalization } from 'survey-creator-knockout';
import { Serializer } from 'survey-vue';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import 'survey-core/survey.i18n';
import 'survey-creator-core/survey-creator-core.i18n';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import UpdateForm from '@/graphQlQueries/mutations/forms/updateForm';

export default {
  name: 'ModalEditFormContent',
  mixins: [UserDataAndAccess],
  props: {
    formDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      surveyData: this.formDetails.form_data || null,
      surveyCreator: null,
    };
  },
  mounted() {
    this.$refs.modalEditFormContent.show();
  },
  methods: {
    async initializeSurveyCreator() {
      await this.stopLoader();

      const creatorOptions = {
        showSurveyTitle: false,
      };
      console.log('this.isSysAdmin', this.userObject.isSysAdmin);
      this.userObject.isSysAdmin = false;
      if (!this.userObject.isSysAdmin) {
        // available types: https://surveyjs.io/form-library/documentation/api-reference/question#getType
        creatorOptions.questionTypes = ['radiogroup', 'rating', 'checkbox', 'dropdown', 'boolean', 'text', 'comment'];
        creatorOptions.allowChangeThemeInPreview = false;
        creatorOptions.showJSONEditorTab = false;
        creatorOptions.allowEditExpressionsInTextEditor = false;
        creatorOptions.pageEditMode = 'single';
        Serializer.getProperty('boolean', 'title').visible = false;
        Serializer.getProperty('boolean', 'visible').visible = false;
      } else {
        creatorOptions.showJSONEditorTab = true;
        creatorOptions.showLogicTab = true;
      }
      this.surveyCreator = new SurveyCreator(creatorOptions);

      if (this.languageOfBrowser === 'de') {
        editorLocalization.currentLocale = 'de';
      }

      this.surveyCreator.text = JSON.stringify(this.surveyData);
      this.surveyCreator.saveSurveyFunc = this.saveSurveyJson;
      this.surveyCreator.render('surveyCreator');
    },
    async stopLoader() {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: { isIndicatorLoading: false },
      });
    },
    saveSurveyJson(saveNo, callback) {
      callback('success', saveNo);
    },
    async validateFormContent() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateForm,
          variables: {
            obj: {
              form_id: this.formDetails.form_id,
              name: null,
              description: null,
              form_data: this.surveyCreator.JSON,
            },
          },
        });
        this.$emit('editFormContent');
        this.$refs.modalEditFormContent.hide();
      } catch (error) {
        console.error(error);
      }
    },
    cancelModal() {
      this.$emit('cancel');
    },
  },
};
</script>

<style>
#surveyCreator {
  height: calc(100vh - 63px - 81px);
}
.modal.show .modal-dialog.modal-full {
  max-width: calc(100% - 10px);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
}
</style>
